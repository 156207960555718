import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";
import copyIcon from "../Assets/Icons/copy.png";

const DirectRequest = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  const showAlert = () => {
    const divElements = document.getElementsByClassName("codelines");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      const imgElement = el.childNodes[0].childNodes[0];
      const alertCopy = el.childNodes[1];
      const alertCopied = el.childNodes[2];
      imgElement.addEventListener("click", () => {
        alertCopied.classList.add("copied-code-active");
        setTimeout(() => {
          alertCopied.classList.remove("copied-code-active");
        }, 1000);
      });
      imgElement.addEventListener("mouseover", () => {
        alertCopy.classList.add("copy-code-active");
        setTimeout(() => {
          alertCopy.classList.remove("copy-code-active");
        }, 1000);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          DirectRequest
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The following log messages are related to the direct request model,
          which is commonly used in combination with an on-chain oracle or
          operator contract that the Chainlink node observes for emitted event
          logs.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="directrequest_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] DirectRequest: OracleRequest log mailbox is over
                capacity - dropped the oldest log
              </div>
            </div>

            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If a consumer sends a Chainlink request to an oracle or operator
            contract an event log is emitted containing a job ID and other
            request parameters. The Chainlink node picks up the log and
            initiates a job run if the job ID is matching one of those in its
            database. This error message indicates that there is a high amount
            of requests and the Chainlink node is not able to process all
            related logs which could lead to missed job runs.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Decrease the direct request frequency for this particular
              Chainlink node
            </li>
            <li>
              Do not list your node details publicly if you want to avoid an
              unpredictable amount of requests
            </li>
            <li>
              Use the following database commands to whitelist requesters for a
              single job (always backup your node and database before you
              manually change anything in the database!)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>2</li>
              <li>
                <code>3</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "SELECT * FROM initiators;  UPDATE initiators SET requesters='$ADDRESS' WHERE job_spec_Id='$JOB_ID "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>SELECT * FROM initiators; </code>
              </li>
              <li></li>
              <li>
                <code>
                  UPDATE initiators SET requesters='$ADDRESS' WHERE
                  job_spec_Id='$JOB_ID';
                </code>
              </li>
            </ul>
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Use the following database commands to whitelist requesters for
              all RunLog jobs
            </li>
          </ul>
          {/* <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
            </ul>
            <ul className="codelines">
              <li>
                <code>
                  UPDATE initiators SET
                  requesters='$ADDRESS1,$ADDRESS2,$ADDRESS3' WHERE type
                  ='runlog';
                </code>
              </li>
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "UPDATE initiators SET requesters='$ADDRESS1,$ADDRESS2,$ADDRESS3' WHERE type ='runlog'; "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
            </ul>
          </div> */}
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "UPDATE initiators SET requesters='$ADDRESS1,$ADDRESS2,$ADDRESS3' WHERE type ='runlog';"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>
                  UPDATE initiators SET
                  requesters='$ADDRESS1,$ADDRESS2,$ADDRESS3' WHERE type
                  ='runlog';
                </code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="directrequest_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] DirectRequest: failed executing run
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node checks if the request was sent by a valid
            requester and if the payment is sufficient to initiate a job run.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the
              <code>MINIMUM_CONTRACT_PAYMENT_LINK_JUELS</code>
              setting of the Chainlink node or relevant job
            </li>
            <li>
              Use the following database commands to whitelist requesters for a
              single job (always backup your node and database before you
              manually change anything in the database!)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>2</li>
              <li>3</li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "SELECT * FROM initiators;  UPDATE initiators SET requesters='$ADDRESS' WHERE job_spec_Id='$JOB_ID "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>SELECT * FROM initiators;</code>
              </li>
              <li></li>
              <li>
                <code>
                  UPDATE initiators SET requesters='$ADDRESS' WHERE
                  job_spec_Id='$JOB_ID';
                </code>
              </li>
            </ul>
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Use the following database commands to whitelist requesters for
              all RunLog jobs
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "UPDATE initiators SET requesters='$ADDRESS1,$ADDRESS2,$ADDRESS3' WHERE type ='runlog';"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>
                  UPDATE initiators SET
                  requesters='$ADDRESS1,$ADDRESS2,$ADDRESS3' WHERE type
                  ='runlog';
                </code>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DirectRequest;
