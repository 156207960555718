import React from "react";

import LinkImage from "../../Assets/images/link-image.svg";
import footerLogo from "../../Assets/images/footer-logo.svg";
import upArrow from "../../Assets/images/upArrow.svg";
import twitter from "../../Assets/Icons/twitter.svg";
import discord from "../../Assets/Icons/discord.svg";
import medium from "../../Assets/Icons/medium.svg";
import telegram from "../../Assets/Icons/telegram.svg";
import github from "../../Assets/Icons/github.png";

import "./footer.css";

const Footer = ({ theme }) => {
  return (
    <footer>
      <div
        className={
          theme === "dark"
            ? "landing-footer_feedback-dark"
            : "landing-footer_feedback"
        }
      >
        <div className="footer_feedback-content">
          <h1>We value your feedback...</h1>
          <h3>Is something missing or out of date?</h3>
          <a
            target="_blank"
            href="https://github.com/Linkriver/nodelogs-content"
          >
            <button
              className={
                theme === "dark"
                  ? "feedback-content_btn-dark"
                  : "feedback-content_btn"
              }
            >
              <span className="feedback-content-btn-text">Suggest edits</span>
              <img src={github} className="footer-social-icon" />
            </button>
          </a>

          <img src={LinkImage} className="feedback_link-img" />
        </div>
      </div>
      <div
        className={
          theme === "dark"
            ? "landing-footer_socials-dark"
            : "landing-footer_socials"
        }
      >
        <div className="footer-wrapper">
          <div className="logo-wrapper">
            <a href="https://linkriver.io/" target="_blank">
              <img src={footerLogo} />
            </a>
          </div>
          <div className="contact-wrapper">
            <h3 className="contact">Contact</h3>
            <div className="link">
              <a href="https://linkriver.io" target="_blank">
                https://linkriver.io/
              </a>
            </div>
            <div className="link">contact@linkriver.io</div>
          </div>
          <div className="socials-wrapper">
            <h3 className="socials">Social</h3>
            <div className="social-icons">
              <a href="https://twitter.com/LinkRiver_io" target="_blank">
                <img src={twitter} className="footer-social-icon" />
              </a>
              <a href="https://discord.gg/xRWKtpjA9F" target="_blank">
                <img src={discord} className="footer-social-icon" />
              </a>
              <a href="https://linkriver.medium.com/" target="_blank">
                <img src={medium} className="footer-social-icon" />
              </a>
              <a href="https://t.me/joinchat/FymY1IbBeOJb1FKz" target="_blank">
                <img src={telegram} className="footer-social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
