import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";

import "./logsPage.css";

const PeriodicBackups = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          PeriodicBackups
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The Chainlink node database can be backed up automatically at regular
          time intervals.{" "}
          <a
            href="https://docs.chain.link/docs/miscellaneous/#importing-a-keystore"
            className="inline-link"
            target="_blank"
          >
            Official Chainlink documentation
          </a>
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="periodicbackups_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Invalid path for DATABASE_BACKUP_DIR - please set it to
                a valid directory path
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Make sure an existing directory path is used</li>
            <li>Default: Chainlink root directory</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="periodicbackups_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Database backup frequency is too small. Please set it to
                at least
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Set a positive regular time interval (at least 1m) for dumping the
              database
            </li>
            <li>
              Examples with valid time unit (e.g. „m“, „h“):
              <code>DATABASE_BACKUP_FREQUENCY=7h</code>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="periodicbackups_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] DatabaseBackup: Failed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Make sure a valid directory path is used</li>
            <li>
              Make sure there is enough disk space for storing the backup file
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PeriodicBackups;
