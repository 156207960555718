import React from "react";

const Card = ({ src, headline, description, href, theme }) => {
  return (
    <div
      className={
        theme === "dark" ? "landing-menu_item-dark" : "landing-menu_item"
      }
    >
      <div className="menu_item-logo">
        <img src={src} className="redirect-icon" />
      </div>
      <hr />
      <div
        className={
          theme === "dark" ? "menu_item-topic-dark" : "menu_item-topic"
        }
      >
        {headline}
      </div>
      <div
        className={
          theme === "dark"
            ? "menu_item-description-dark"
            : "menu_item-description"
        }
      >
        {description}
      </div>
      <hr />
      <a
        href={href}
        target="_blank"
        style={{ width: "70%", display: "contents" }}
      >
        <button
          className={theme === "dark" ? "menu_item-btn-dark" : "menu_item-btn"}
        >
          Learn More
        </button>
      </a>
    </div>
  );
};

export default Card;
