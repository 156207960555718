import React, { useState, useEffect } from "react";

import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
import Landing from "./Pages/Landing/Landing";
import BalanceMonitor from "./Pages/BalanceMonitor";
import BulletproofTxManager from "./Pages/BulletproofTxManager";
import DirectRequest from "./Pages/DirectRequest";
import FluxMonitor from "./Pages/FluxMonitor";
import GasEstimator from "./Pages/GasEstimator";
import Log from "./Pages/Log";
import HeadTracker from "./Pages/HeadTracker";
import Posgres from "./Pages/Posgres";
import { Route, Switch } from "react-router";

import Markdown from "markdown-to-jsx";
import styled from "styled-components";
import BalanceMonitorTest from "./Pages/BalanceMonitorTest";
import Client from "./Pages/Client";
import Keystore from "./Pages/Keystore";
import OCR from "./Pages/OCR";
import PeriodicBackups from "./Pages/PeriodicBackups";
// const mdFile = require("./example.md");

function App() {
  const [theme, setTheme] = useState();
  const file_name = "example.md";
  const [post, setPost] = useState("");

  useEffect(() => {
    import(`./${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });

  useEffect(() => {
    getCurrentTheme();
  }, []);

  useEffect(() => {
    localStorage.setItem("themeType", theme);
  }, [theme]);

  const getCurrentTheme = () => {
    const initialState =
      localStorage.getItem("themeType") === "dark" ||
      localStorage.getItem("themeType") === null
        ? "dark"
        : "light";

    setTheme(initialState);
  };

  const toggleTheme = () => {
    if (theme == "light" || theme == null) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  return (
    <Switch>
      <>
        {/* <Markdown options={options}>{post}</Markdown> */}
        <Header toggleTheme={toggleTheme} theme={theme} />
        <Sidebar theme={theme} />
        <Route exact path="/" component={() => <Landing theme={theme} />} />
        <Route
          exact
          path="/balancemonitor"
          component={() => <BalanceMonitor theme={theme} />}
        />

        <Route
          exact
          path="/bulletprooftxmanager"
          component={() => <BulletproofTxManager theme={theme} />}
        />
        <Route
          exact
          path="/directrequest"
          component={() => <DirectRequest theme={theme} />}
        />
        <Route
          exact
          path="/fluxmonitor"
          component={() => <FluxMonitor theme={theme} />}
        />
        <Route
          exact
          path="/gasestimator"
          component={() => <GasEstimator theme={theme} />}
        />
        <Route
          exact
          path="/headtracker"
          component={() => <HeadTracker theme={theme} />}
        />
        <Route exact path="/Log" component={() => <Log theme={theme} />} />
        <Route
          exact
          path="/postgres"
          component={() => <Posgres theme={theme} />}
        />

        <Route
          exact
          path="/periodicbackups"
          component={() => <PeriodicBackups theme={theme} />}
        />

        {/* <Route
          exact
          path="/BalanceMonitorTest"
          component={() => <BalanceMonitorTest theme={theme} />}
        /> */}
        <Route
          exact
          path="/client"
          component={() => <Client theme={theme} />}
        />

        <Route
          exact
          path="/keystore"
          component={() => <Keystore theme={theme} />}
        />
        <Route exact path="/ocr" component={() => <OCR theme={theme} />} />

        <Footer theme={theme} />
      </>
    </Switch>
  );
}

export default App;

const MainTitle = styled.h1`
  color: #2980b9;
  border-bottom: 3px solid #333;
`;

const SectionTitle = styled.h2`
  color: #895fad;
  border-bottom: 2px dashed #333;
  text-align: right;
  margin: 0 20px;
`;

const SubSectionTitle = styled.h3`
  color: #2980b9;
  text-transform: uppercase;
`;

const ParaText = styled.p`
  color: green;
  text-align: left;
  margin: 0 20px;
  line-height: 1.375rem;
`;

const StrongText = styled.strong`
  color: black;
  padding: 2px;
  text-decoration: underline;
`;

const ExtLink = styled.a.attrs({
  target: "_blank",
})`
  color: #2980b9;
  &:hover {
    color: #ffd700;
  }
`;

const Code = styled.code`
  color: red;
  font-size: 1.125rem;
`;

const DividerLine = styled.hr`
  border: 1px solid #2980b9;
`;

const Image = styled.img`
  border: 5px solid #895fad;
  border-left: none;
  border-right: none;
`;

const options = {
  overrides: {
    h1: {
      component: MainTitle,
    },
    h2: {
      component: SectionTitle,
    },
    h3: {
      component: SubSectionTitle,
    },
    p: {
      component: ParaText,
    },
    strong: {
      component: StrongText,
    },
    a: {
      component: ExtLink,
    },
    code: {
      component: Code,
    },
    img: {
      component: Image,
    },
    hr: {
      component: DividerLine,
    },
  },
};
