import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import copyIcon from "../Assets/Icons/copy.png";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import "./logsPage.css";

const HeadTracker = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  const showAlert = () => {
    const divElements = document.getElementsByClassName("codelines");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      const imgElement = el.childNodes[0].childNodes[0];
      const alertCopy = el.childNodes[1];
      const alertCopied = el.childNodes[2];
      imgElement.addEventListener("click", () => {
        alertCopied.classList.add("copied-code-active");
        setTimeout(() => {
          alertCopied.classList.remove("copied-code-active");
        }, 1000);
      });
      imgElement.addEventListener("mouseover", () => {
        alertCopy.classList.add("copy-code-active");
        setTimeout(() => {
          alertCopy.classList.remove("copy-code-active");
        }, 1000);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          HeadTracker
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The HeadTracker stores the latest block number in a safe manner and
          reconstitutes the last block number from the data store on reboot.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="headtracker_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Error in new head subscription, unsubscribed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node is not able to create a new head subscription
            through the JSON-RPC method <code>eth_subscribe</code> . This issue
            might be related to the remote RPC endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="headtracker_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] HeadTracker: have not received a head for
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node has not received a head for the mentioned period
            of time. Block times can sometimes vary depending on the network and
            its conditions, so this log might be produced occasionally. The
            default period after which this log is produced is chain-specific
            for example: <br /> Ethereum mainnet: 1min, Polygon mainnet: 15sec{" "}
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="headtracker_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Headlistener: Failed to connect to ethereum node
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node is not able to establish a websocket connection
            to the remote RPC endpoint (full node). This connection is
            established through the JSON-RPC method <code>eth_subscribe</code> .
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="headtracker_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] ethereum ChainID doesn't match chainlink config.ChainID
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the <code>ETH_CHAIN_ID </code> setting of the Chainlink node
            </li>
            <li>
              Check if the Chainlink node is connected to the right RPC endpoint
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="headtracker_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] HeadTracker: got very old block with number (highest
                seen was ). This is a problem and either means a very deep
                re-org occurred, or the chain went backwards in block numbers.
                This node will not function correctly without manual
                intervention
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              If the Chainlink node has previously synchronized another chain
              and stored the block headers in its database, it can be helpful to
              drop the database and re-initialize the node with the correct
              <code>ETH_URL </code>
              (make sure to backup the private key beforehand if necessary using
              the following commands)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
              <li>
                <code>4</code>
              </li>
              <li>
                <code>5</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "chainlink admin login; chainlink keys eth list; chainlink keys eth export -p ./path/to/pw -o;  ./output/file.json <KeyID>}"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>chainlink admin login</code>
              </li>
              <li></li>
              <li>
                <code>chainlink keys eth list</code>
              </li>
              <li></li>
              <li>
                <code>
                  chainlink keys eth export -p ./path/to/pw -o
                  ./output/file.json {"<KeyID>"}
                </code>
              </li>
            </ul>
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node
              (Full-node-as-a-Service subscription and renew or switch the plan
              to prevent RPC rate limits from being hit if necessary)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node check:
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Whether an error occurred requiring to resync the node (firewall,
              bad block, OOM)
            </li>
            <li>
              Whether it happens due to the node’s performance (hardware specs,
              disc’s IOPS)
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HeadTracker;
