import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";

import "./logsPage.css";

const GasEstimator = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          GasEstimator
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The GasEstimator can either run in <code>BlockHistory</code>,
          <code>FixedPrice</code> or <code>Optimism2</code> mode and manages the
          setting of the gas price for the Chainlink node’s outgoing
          transactions. The safest way to prevent stuck or overpaid transactions
          on most networks is to run the <code>BlockHistory</code> mode.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="gasestimator_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] BlockHistoryEstimator: GAS_UPDATER_BLOCK_HISTORY_SIZE= is
                greater than ETH_FINALITY_DEPTH=, blocks deeper than finality
                depth will be refetched on every block history estimator cycle,
                causing unnecessary load on the eth node. Consider decreasing
                GAS_UPDATER_BLOCK_HISTORY_SIZE or increasing ETH_FINALITY_DEPTH
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The BlockHistoryEstimator listens for new heads and updates the base
            gas price dynamically based on the configured percentile of gas
            prices in that block.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="gasestimator_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] BlockHistoryEstimator: error fetching blocks
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node is not able to fetch the block from its remote
            RPC endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="gasestimator_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] BlockHistoryEstimator: cannot fetch, current block
                height is lower than GAS_UPDATER_BLOCK_DELAY=
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The block delay variable determines the number of blocks that the
            block history estimator trails behind the head and thus which block
            to fetch.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the <code>BLOCK_HISTORY_ESTIMATOR_BLOCK_DELAY</code> setting
              and decrease it if necessary
            </li>
            <li>
              Restart the node to apply the changes to the environmental file
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="gasestimator_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] BlockHistoryEstimator: cannot calculate percentile gas
                price
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot set a new default gas price as there are
            no suitable transactions in the block history to calculate the
            percentile gas price to choose.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="gasestimator_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] Calculated gas price of Wei exceeds
                ETH_MAX_GAS_PRICE_WEI=, setting gas price to the maximum allowed
                value of Wei instead
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot set the new default gas price as its set
            maximum of <code>ETH_MAX_GAS_PRICE_WEI</code> would be exceeded.
            Transactions will never be sent with a higher gas price, the default
            values for this setting are chain-specific.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check if the target network is facing any gas price abnormalities
            </li>
            <li>
              Increase
              <code>ETH_MAX_GAS_PRICE_WEI</code> if necessary
            </li>
            <li>
              Decrease
              <code>BLOCK_HISTORY_ESTIMATOR_TRANSACTION_PERCENTILE</code>
              if necessary
            </li>
            <li>
              Restart the node to apply the changes to the environmental file
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="gasestimator_id-6">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] Calculated gas price of Wei falls below
                ETH_MIN_GAS_PRICE_WEI=, setting gas price to the minimum allowed
                value of Wei instead
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot set the new default gas price as it would
            fall below its set minimum of ETH_MIN_GAS_PRICE_WEI. Transactions
            will never be sent with a lower gas price, the default values for
            this setting are chain-specific.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Decrease <code>ETH_MIN_GAS_PRICE_WEI</code> if necessary
            </li>
            <li>
              Increase
              <code>BLOCK_HISTORY_ESTIMATOR_TRANSACTION_PERCENTILE</code> if
              necessary
            </li>
            <li>
              Restart the node to apply the changes to the environmental file
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default GasEstimator;
