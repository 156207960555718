import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";
import copyIcon from "../Assets/Icons/copy.png";

const Keystore = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  const showAlert = () => {
    const divElements = document.getElementsByClassName("codelines");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      const imgElement = el.childNodes[0].childNodes[0];
      const alertCopy = el.childNodes[1];
      const alertCopied = el.childNodes[2];
      imgElement.addEventListener("click", () => {
        alertCopied.classList.add("copied-code-active");
        setTimeout(() => {
          alertCopied.classList.remove("copied-code-active");
        }, 1000);
      });
      imgElement.addEventListener("mouseover", () => {
        alertCopy.classList.add("copy-code-active");
        setTimeout(() => {
          alertCopy.classList.remove("copy-code-active");
        }, 1000);
      });
    });
  };
  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          Keystore
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          This service enables the generation of new keys or the import, export
          and erasure of existing ones.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="keystore_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] key with ID already exists
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            During the first initialization, the Chainlink node generates a key
            itself, but you can also import a key from a JSON keystore file
            manually. This error indicates that you are trying to import an
            already existing key.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure to have followed the{" "}
              <a
                href="https://docs.chain.link/docs/miscellaneous/#importing-a-keystore"
                className="inline-link"
                target="_blank"
              >
                official Chainlink documentation
              </a>
            </li>
            <li>
              If you want to delete an existing key do not forget to make a
              backup if necessary by exporting it using the following CLI
              commands
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
              <li>
                <code>4</code>
              </li>
              <li>
                <code>5</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "chainlink admin login; chainlink keys eth list; chainlink keys eth export -p ./path/to/pw -o;  ./output/file.json <KeyID>"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>chainlink admin login</code>
              </li>
              <li></li>
              <li>
                <code>chainlink keys eth list</code>
              </li>
              <li></li>
              <li>
                <code>
                  chainlink keys eth export -p ./path/to/pw -o
                  ./output/file.json {" <KeyID>"}
                </code>
              </li>
            </ul>
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Then delete the key using</li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "chainlink keys eth delete  <KeyID>"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <code>chainlink keys eth delete {" <KeyID>"}</code>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="keystore_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] unable to find eth key with id
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure to have followed the{"  "}
              <a
                href="https://docs.chain.link/docs/miscellaneous/#importing-a-keystore"
                className="inline-link"
                target="_blank"
              >
                official Chainlink documentation
              </a>
            </li>
            <li>
              Check the existing accounts by using the following CLI commands
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "chainlink admin login; chainlink keys eth list;}"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>chainlink admin login</code>
              </li>
              <li></li>
              <li>
                <code>chainlink keys eth list</code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="keystore_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] No P2P_PEER_ID set, defaulting to first key in database
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            This message occurs if a Chainlink node is initialized with
            <code> FEATURE_OFFCHAINREPORTING=true</code> for the first time and
            no <code>P2P_PEER_ID</code> is set in the environmental file, so
            that a new ID is created and automatically used as default one.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              <code>TheP2P_PEER_ID</code> can be found in the key section of the
              web GUI or displayed using the Chainlink CLI commands
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "chainlink admin login; chainlink keys eth list;}"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>chainlink admin login</code>
              </li>
              <li></li>
              <li>
                <code>chainlink keys eth list</code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="keystore_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] multiple p2p keys found but peer ID was not set - you
                must specify a P2P_PEER_ID
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The <code>P2P_PEER_ID</code> is used for OCR jobs and needs to be
            set in the environmental file in order to enable the communication
            of the Chainlink node within the peer-to-peer network.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Keystore;
