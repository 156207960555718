import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import "./logsPage.css";

const Log = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          Log
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          This service manages log subscription requests for the Chainlink node
          without creating a new subscription for each request and backfills
          logs in case of a node crash or restart.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="log_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Log subscriber could not create subscription to Ethereum
                node
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node could not create a new log subscription to listen
            to on-chain events and will not be able to interact with the target
            network appropriately. This indicates an issue with the remote RPC
            endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node check:
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Whether an error occurred requiring to resync the node (firewall,
              bad block, OOM)
            </li>
            <li>
              Whether it happens due to the node’s performance (hardware specs,
              disc’s IOPS)
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] LogBroadcaster: BlockBackfillSkip is set to true,
                preventing a deep backfill - some earlier chain events might be
                missed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Backfilling guarantees to check older heads for relevant event logs
            in case the Chainlink node crashed or has been restarted. This error
            indicates that the Chainlink node will not get the event logs from
            older blocks and might skip potential job runs. BlockBackfillSkip is
            set to false by default to avoid this in case of a node crash or
            restart.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              <a
                href="https://docs.chain.link/docs/configuration-variables/#block_backfill_skip"
                className="inline-link"
                target="_blank"
              >
                Official Chainlink documentation
              </a>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] LogBroadcaster: Error in the event loop - will reconnect
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The log subscription has been closed and could not be recreated,
            this might result from an issue with the remote RPC endpoint (full
            node) or a changed set of on-chain contracts the Chainlink node
            should listen to for events.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
            <li>
              Make sure your node address was added to the on-chain contracts it
              should interact with
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] LogBroadcaster: Detected a large block number difference
                between a log and recently seen head. This may indicate a
                problem with data received from the chain or major network
                delays
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node check:
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Whether an error occurred requiring to resync the node (firewall,
              bad block, OOM)
            </li>
            <li>
              Whether it happens due to the node’s performance (hardware specs,
              disc’s IOPS)
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] LogBroadcaster: Backfill - could not fetch latest block
                header, will retry
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node check:
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Whether an error occurred requiring to resync the node (firewall,
              bad block, OOM)
            </li>
            <li>
              Whether it happens due to the node’s performance (hardware specs,
              disc’s IOPS)
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-6">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] LogBroadcaster: Deadline exceeded, unable to backfill a
                batch of logs. Consider setting EvmLogBackfillBatchSize to a
                lower value
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Blocks can be read in batches to avoid hitting the websocket request
            data limit if the number of blocks to check is high and differs
            significantly from the latest head. This may delay the processing of
            the latest log and the Chainlink node may miss job requests.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Set <code>BlockBackfillSkip</code> to <code>true</code> if
              necessary and restart the node
            </li>
            <li>
              <a
                href="https://docs.chain.link/docs/configuration-variables/#eth_log_backfill_batch_size"
                className="inline-link"
                target="_blank"
              >
                Official Chainlink documentation
              </a>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-7">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] LogBroadcaster: Inner deadline exceeded, unable to
                backfill a batch of logs. Consider setting
                EvmLogBackfillBatchSize to a lower value
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Set <code>EVM_LOG_BACKFILL_BATCH_SIZE</code>
              to a lower value, or
            </li>

            <li>
              Set <code>BlockBackfillSkip</code>
              to <code>true</code> if necessary and restart the node
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="log_id-8">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] LogBroadcaster: Unable to backfill a batch of logs after
                retries
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Set <code>EVM_LOG_BACKFILL_BATCH_SIZE</code>
              to a lower value, or
            </li>

            <li>
              Set <code>BlockBackfillSkip</code>
              to <code>true</code> if necessary and restart the node
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Log;
