import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";

import "./logsPage.css";

const FluxMonitor = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          FluxMonitor
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The Flux Monitor job type enables features like decentralized data
          feeds by empowering Chainlink nodes to read on-chain smart contract
          states (e.g. latest price stored in an aggregator contract) and
          examining a calculated median of freshly aggregated off-chain data for
          price deviations. Each node participating in such a decentralized
          oracle network gets the data by polling external adapters for
          different data provider APIs and submits the result on-chain when the
          pre-defined conditions are met. <br />
          <a
            href=" https://docs.chain.link/docs/jobs/types/flux-monitor/"
            className="inline-link"
            target="_blank"
          >
            Official Chainlink documentation
          </a>
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] unable to determine hibernation status
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            FluxMonitor jobs can run in hibernation mode, the Chainlink node
            checks if the contract’s flags enabling this mode are lowered, this
            error indicates an issue with the remote RPC endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the contract address</li>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] failed to get list of oracles from FluxAggregator
                contract
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node needs to get the list of oracles that are allowed
            to submit observations to the AccesControlledAggregator in order to
            set the oracle address which matches the key of the node.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure to give access to all oracles that are supposed to
              submit to the aggregator contract by correctly adding the node
              addresses to the corresponding smart contract
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] None of the node's keys matched any oracle addresses,
                using first available key. This flux monitor job may not work
                correctly
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure to give access to all oracles that are supposed to
              submit to the aggregator contract by correctly adding the node
              addresses to the corresponding smart contract
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Error determining if log was already consumed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            It should be determined if a log is a duplicate of an already known
            one which may happen due to the backfilling feature and should
            result in ignoring this particular log. This error may occur after a
            node crash or restart or indicate issues with the remote RPC
            endpoint (full node).
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Error determining if flag is still raised
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node checks if both flags enabling the hibernation
            mode are lowered after receiving a <code>FlagsFlagRaised</code> log,
            this error indicates an issue with the remote RPC endpoint (full
            node).
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the contract address</li>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-6">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] could not fetch oracleRoundState
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot check if a round has successfully closed
            with a new answer because it’s unable to consume the AnswerUpdated
            log. This may be related to an issue with the remote RPC endpoint
            (full node).
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-7">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] error determining round stats / run status for round
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot determine the run status of a new round,
            this may be related to an issue with the remote RPC endpoint (full
            node).
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-8">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Ignoring new round request: error fetching eligibility
                from contract
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node always checks its eligibility to submit to a new
            round by checking the round state, if it has already submitted to it
            and if the aggregator contract can pay it. In this case it is not
            able to fetch the needed data from the contract, which indicates an
            issue with the remote RPC endpoint (full node).
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-9">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] error executing new run for job ID name
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Make sure the on-chain configs and job spec are correct</li>
            <li>Make sure your external adapters are operational</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="fluxmonitor_id-10">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] LogBroadcaster is not connected to Ethereum node,
                skipping poll
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node is not able to interact with the target network
            as it is not connected with a remote RPC endpoint (full node).
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure to reestablish a functioning blockchain connection
            </li>
            <li>
              Check out existing automated failover solutions for the remote RPC
              endpoint of the Chainlink node, for example:{" "}
              <a
                href="https://github.com/Fiews/ChainlinkEthFailover"
                className="inline-link"
                target="_blank"
              >
                Fiews/ChainlinkEthFailover
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FluxMonitor;
