import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";

import "./logsPage.css";

const Posgres = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    console.log(location.hash);
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          Postgres
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The Postgres service manages the connection and communication between
          the Chainlink node and its local or remote PostgreSQL database.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="postgres_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] could not get advisory lock for classID
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The advisory lock provides a mechanism that locks the database as an
            application-oriented session without simultaneously preventing write
            operations (e.g. manual editing of the database). This means that
            other applications can be in the queue and have full access to the
            database as soon as the lock is released. It can be used for
            automated Chainlink node failover architectures by enabling a
            secondary node to wait for the lock to be released to jump in
            whenever the primary node fails (this practice is no longer
            recommended). This log indicates that the lock could not be closed.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure that the <code>ETH_DATABASE_URL</code> environmental
              variables of the primary and secondary Chainlink nodes are
              identical
            </li>
            <li>
              Check if the PostgeSQL database settings for the advisory lock is
              enabled
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="postgres_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] Postgres event broadcaster: disconnected, trying to
                reconnect…
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The connection to the PostgreSQL database might have been
            interrupted and the Chainlink node tries to reconnect to resume the
            exchange with it.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the firewall settings of the Chainlink node’s host machine
              for outgoing traffic
            </li>
            <li>
              Check the firewall settings of the PostgreSQL server for incoming
              traffic
            </li>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="postgres_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] Postgres event broadcaster: reconnect attempt failed,
                trying again…
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node was not able to reconnect to the PostgreSQL
            database. It will produce this log until it can successfully
            reconnect.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the firewall settings of the Chainlink node’s host machine
              for outgoing traffic
            </li>
            <li>
              Check the firewall settings of the PostgreSQL server for incoming
              traffic
            </li>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Posgres;
