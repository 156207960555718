import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";

import "./logsPage.css";

const OCR = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };
  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          OCR
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The off-chain reporting protocol enables features like decentralized
          data feeds by empowering Chainlink nodes to generate signed reports of
          aggregated data within an off-chain peer-to-peer network. Each
          Chainlink node aggregates data from a set of data sources, the
          individual observations are then compared, sorted and finally approved
          by all participating oracles. If the predefined conditions are met
          (e.g. minimum price deviation or maximum elapsed time), the final
          report is transmitted on-chain by one single Chainlink node, the
          aggregator smart contract then verifies that it was signed by a quorum
          of oracles, so that the median value of the report can be exposed to
          consuming contracts.
          <br />
          <br />
          The main purpose of the log messages in this section is to provide a
          better understanding of the functioning of the OCR protocol and the
          different roles a single Chainlink node can take.
          <br />
          <br />
          <a
            href="https://docs.chain.link/docs/off-chain-reporting/"
            className="inline-link"
            target="_blank"
          >
            Official Chainlink documentation
          </a>
          <br />
          <a
            href="
            https://research.chain.link/ocr.pdf"
            className="inline-link"
            target="_blank"
          >
            Chainlink Labs OCR research paper
          </a>
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Pacemaker: Timeout while restoring state from database
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            OCR reports are generated in epochs, the pacemaker algorithm drives
            the report generation by keeping track of the state and message
            handling of an oracle to ensure continuous progress of the OCR
            protocol.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Pacemaker: error while restoring state from database
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] non-leader round sender
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The report generation follower algorithm manages observation
            requests received from the leader of the current report generation
            instance. In this case the leader seems to be incorrect, thus the
            protocol halts as an oracle may be trying to usurp the lead.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Ensure the integrity of the network participants </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] out of bounds round round rMax msgRound
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            This message warns of a potentially malicious leader as the round
            value is unusually high and should prevent denial-of-service
            attacks, the oracles exit the report generation protocol and emit a{" "}
            <code>changeleader</code> event.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Ensure the integrity of the network participants</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] messageObserveReq: could not make SignedObservation
                observation
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            After receiving an observe request the oracle collects a new
            observation (calls its external adapters to fetch the needed data)
            and sends a signed message to the leader. The observation needs to
            be signed with the <code>offChainPrivateKey</code> of the oracle in
            order to produce a valid observe message for which it gets paid.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-6">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] MakeSignedObservation produced invalid signature
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            This error indicates an issue with the{" "}
            <code>offChainPrivateKey</code> of the node which is necessary to
            produce a valid observe message for which the oracle gets paid.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-7">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] messageReportReq after report sent
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If the report generation leader has received sufficient valid
            observe messages for a certain round, it sorts the observations by
            their values and sends a report request message to the report
            generation follower nodes. In this case a report has already been
            sent and will not be submitted again.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-8">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] messageReportReq after round completed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The report request will not be processed further because the round
            is no longer up-to-date, which may result from network delays.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-9">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] messageReportReq: could not validate report sent by
                leader
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node could not validate that all signatures in the
            received report are valid.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-10">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] messageReportReq: failed to sign report
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node could not validate that all signatures in the
            received report are valid.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-11">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] ReportGeneration: DataSource errored
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node could not get a successful response from its
            assigned data sources and may therefore not be able to send an
            observation to the current leader.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Make sure your external adapters are operational</li>
            <li>Make sure you did not hit your data provider rate limits</li>
            <li>Check if the used APIs are responsive</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-12">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] shouldReport: blockchain interaction timed out,
                returning true
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            This error may indicate a deep reorg of the target network or issues
            with the remote RPC endpoint (full node) and prevents the Chainlink
            node from transmitting reports on-chain. However, it does not
            prevent the node from contributing to the report generation
            protocol.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-13">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] shouldReport: Error during LatestTransmissionDetails
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            There may be too many reports created to determine whether a new
            final report should be sent, which depends on the predefined
            conditions of the data feed (e.g. price deviation, elapsed time
            since the last on-chain update).
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-14">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] shouldReport: Error during LatestRoundRequested
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            There may be too many reports created to determine whether a new
            final report should be sent, which depends on the predefined
            conditions of the data feed (e.g. price deviation, elapsed time
            since the last on-chain update).
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-15">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] messages not sorted by value
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The observations were not sorted by value, which indicates issues
            during the report generation and could lead to incorrect results.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-16">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] given oracle ID of is out of bounds (only have public
                keys)
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The report request errors as there is an invalid oracle ID not
            matching the number of known public keys.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Ensure that untrustworthy participants are denied access to the
              peer-to-peer network
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-17">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] not enough observations in report; got , need more than
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            In order to protect results against manipulation, a minimum number
            of oracles is required to report their observations in order to
            generate a valid report.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Ensure that all Chainlink nodes that contribute to the protocol
              are protected against single points of failure and therefore
              highly available and responsive
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-18">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] verifyAttestedReport: dropping final report because it
                has too few signatures
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure that all Chainlink nodes that contribute to the protocol
              are protected against single points of failures and therefore
              highly available and responsive
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-19">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] ReportGeneration: new round number would be larger than
                RMax + 1. Looks like your connection to more than f other nodes
                is not working.
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The report generation protocol produces one report per round and
            repeats this process until it halts and the next protocol instance
            is started, this warning indicates delays of the peer-to-peer
            network and prevents the start of a new round.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Make sure at least one bootstrap node is operational</li>
            <li>
              Make sure that all Chainlink nodes that contribute to the protocol
              are protected against single points of failures and therefore
              highly available and responsive
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-20">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] ReportGeneration: round overflows, cannot start new
                round
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            There are too many rounds that did not end with a valid report,
            which may result from delays of the peer-to-peer network.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Make sure at least one bootstrap node is operational</li>
            <li>
              Make sure that all Chainlink nodes that contribute to the protocol
              are protected against single points of failures and therefore
              highly available and responsive
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-21">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] Non-leader received MessageObserve
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Observe messages are generated by each oracle participating as
            report generation follower and are exclusively sent to the current
            leader, who constructs a report after receiving the observations.
            This indicates irregularities in the message flow of the report
            generation leader protocol instance.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-22">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] MessageObserve carries invalid SignedObservation
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The report generation leader could not validate the signed
            observation sent by one of the oracles.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-23">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] leader's phase conflicts tGrace timeout
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The <code>T_observe</code> grace period is started once the leader
            has received enough observations to construct a report and gives
            slower oracles the time to submit their observations. In this case
            the leader wants to move on to the report phase without the grace
            period being over.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-24">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] messageReport: dropping MessageReport due to not being
                leader of the current epoch
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The oracle that is supposed to send out the final report to get the
            signatures of the other participants is no longer the leader of the
            current epoch, either due to an insufficient number of progress
            events or the predetermined number of rounds being reached, which
            causes the pacemaker protocol to abort this report generation
            instance and start a new one.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-25">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] messageReport: dropping MessageReport due to having
                already received sender's report
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Messages that have been sent more than once may be a result of
            network delays.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-26">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] could not validate signature
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The report generation leader could not validate the{" "}
            <code>OnChainSigningAddress</code>
            of the message sender, thus its identity cannot be verified and the
            report will not be used for the final attested report.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-27">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] contractEpoch() failed during eventTransmit
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The transmission protocol handles the updating of the value that is
            stored in the aggregator contract by causing an oracle to send the
            final report through a transaction on-chain. Reports are only
            allowed if the aggregator contract has seen a report as recent as
            the latest incoming one to prevent similar transmissions. The latest
            contract epoch and thus the eligibility to transmit the final report
            could not be determined, which may be related to issues with the
            remote RPC endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-28">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Failed to serialize contract report
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The latest report received by the aggregator contract determines the
            eligibility to transmit the final report, a failed serialization of
            it may be related to issues with the remote RPC endpoint (full
            node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-29">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] could not compute median
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The median of the latest incoming report must deviate by at least
            the predefined threshold from the median of the final report in
            order to be transmitted on-chain while avoiding needless
            transmissions, in this case it could not be computed.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-30">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] could not take median of observations
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The median of the latest incoming report must deviate by at least
            the predefined threshold from the median of the final report in
            order to be transmitted on-chain while avoiding needless
            transmissions, in this case it could not be computed.
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-31">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Error while persisting pending transmission to database
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            A pending transmission cannot be written to the database.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-32">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Database.StorePendingTransmission timed out
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-33">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] eventTTransmitTimeout: Error while deleting pending
                transmission from database
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-34">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Database.DeletePendingTransmission timed out
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>Check the health and availability of the PostgreSQL server</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-35">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] eventTTransmitTimeout: contractState() failed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The transmit event timed out as the contract state could not be
            determined, this may be related to issues with the remote RPC
            endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-36">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] eventTTransmitTimeout: Transmit timed out
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The transaction containing the final report could not be transmitted
            on-chain, this may be related to issues with the remote RPC endpoint
            (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="ocr_id-37">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] eventTTransmitTimeout: Error while transmitting report
                on-chain
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The transaction containing the final report could not be transmitted
            on-chain, this may be related to issues with the remote RPC endpoint
            (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default OCR;
