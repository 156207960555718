import React, { useState } from "react";
import Data from "../../Assets/data/logs.json";
import search from "../../Assets/Icons/search.svg";
import searchDark from "../../Assets/Icons/searchDark.svg";
import operatorIcon from "../../Assets/Icons/redirect1.svg";
import settingsIcon from "../../Assets/Icons/redirect2.svg";
import operator2Icon from "../../Assets/Icons/redirect3.svg";
import bgnodelogslogo from "../../Assets/images/bgnodelogslogo.svg";
import bgimageDark from "../../Assets/images/bgimage-dark.svg";
import dark1 from "../../Assets/Icons/dark1.svg";
import dark2 from "../../Assets/Icons/dark2.svg";
import dark3 from "../../Assets/Icons/dark3.svg";
import Card from "../../Components/Card/Card";

import { useHistory } from "react-router";

import "./landing.css";

let errorLogs = [];

for (const log of Data) {
  for (const key in log) {
    errorLogs.push(log[key]);
  }
}

let merged = [].concat.apply([], errorLogs);

const Landing = ({ theme }) => {
  let history = useHistory();

  const [logs, setLogs] = useState(null);
  const [logMessage, setLogMessage] = useState(false);

  const handleChange = (e) => {
    const log = merged.filter((log) =>
      log.value.toLowerCase().includes(e.target.value.toLowerCase())
    );
    e.target.value == "" && setLogMessage(false);
    if (log.length > 0) {
      setLogs(log[0]);
    } else {
      setLogs(null);
      setLogMessage(true);
    }
  };

  const handleSearch = (e) => {
    let userInput = e.target.value;
    let similarityLength = 0;
    let chosenLog = null;

    merged.forEach((log) => {
      let result = similarity(userInput, log.value);
      if (result > similarityLength) {
        similarityLength = result;
        chosenLog = log;
      }
    });

    console.log(`${chosenLog?.linkTo}#${chosenLog?._id}`);

    e.charCode === 13 && history.push(`${chosenLog?.linkTo}#${chosenLog?._id}`);
  };

  function similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    let longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    let costs = new Array();
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }
  return (
    <section
      className={theme === "dark" ? "landing-section-dark" : "landing-section"}
      style={
        theme === "dark"
          ? {
              backgroundImage: `url(${bgimageDark})`,
              backgroundRepeat: "no-repeat",
            }
          : {
              backgroundImage: `url(${bgnodelogslogo})`,
              backgroundRepeat: "no-repeat",
            }
      }
    >
      <div className="landing-copy">
        <h1
          className={
            theme === "dark" ? "landing-header-dark" : "landing-header"
          }
        >
          Welcome to nodelogs.link!
        </h1>
        <p className={theme === "dark" ? "landing-text-dark" : "landing-text"}>
          Find Chainlink node log messages references, potential causes for
          occurrence, and debugging suggestions.
        </p>
      </div>
      <div
        className={theme === "dark" ? "landing-search-dark" : "landing-search"}
      >
        <img
          src={
            theme === "dark" && window.innerWidth <= 768 ? searchDark : search
          }
          className="landing-searchIcon"
        />
        <input
          placeholder="Search for log messages..."
          className={theme === "dark" ? "landing-input-dark" : "landing-input"}
          name="errorLogs"
          onKeyPress={handleSearch}
          onChange={handleChange}
          id="searchInput"
        />
      </div>

      <div className="landing-menu">
        <Card
          src={theme === "dark" ? dark1 : operatorIcon}
          headline="What is a Chainlink Node Operator?"
          description="Node operators are the backbone of the Chainlink Network."
          href="https://blog.chain.link/what-is-a-chainlink-node-operator/"
          theme={theme}
        />
        <Card
          src={theme === "dark" ? dark2 : settingsIcon}
          headline="How to set up a Chainlink Node?"
          description="Check out the basic requirements for running a Chainlink node."
          href="https://docs.chain.link/docs/running-a-chainlink-node/"
          theme={theme}
        />
        <Card
          src={theme === "dark" ? dark3 : operator2Icon}
          headline="Becoming a Chainlink Node Operator"
          description="Additional helpful considerations for operating production nodes."
          href="https://www.youtube.com/watch?v=jD9903qyt6w&t=1s"
          theme={theme}
        />
      </div>
    </section>
  );
};

export default Landing;
