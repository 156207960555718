import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";
import copyIcon from "../Assets/Icons/copy.png";

const BulletproofTxManager = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };

  const showAlert = () => {
    const divElements = document.getElementsByClassName("codelines");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      const imgElement = el.childNodes[0].childNodes[0];
      const alertCopy = el.childNodes[1];
      const alertCopied = el.childNodes[2];
      imgElement.addEventListener("click", () => {
        alertCopied.classList.add("copied-code-active");
        setTimeout(() => {
          alertCopied.classList.remove("copied-code-active");
        }, 1000);
      });
      imgElement.addEventListener("mouseover", () => {
        alertCopy.classList.add("copy-code-active");
        setTimeout(() => {
          alertCopy.classList.remove("copy-code-active");
        }, 1000);
      });
    });
  };

  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          BulletproofTxManager
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          This service is responsible for creating, if necessary rebroadcasting
          and eventually confirming all transactions that the Chainlink node
          sends on-chain.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] BulletproofTxManager: dropped old transactions from
                transaction queue
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node drops the oldest transactions as there are too
            many queued ones in order to create a new transaction. A large
            amount of non-broadcast transactions indicates an issue with the
            remote RPC endpoint (full node), or that a previous transaction is
            stuck. If the node is not able to rebroadcast it you may need to
            delete the unconfirmed transactions and pending job runs from the
            database manually.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
            <li>
              Run these commands on your database to delete all unconfirmed
              transactions and pending job runs (always backup your node and
              database before you manually change anything in the database!)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
              <li>
                <code>4</code>
              </li>
              <li>
                <code>5</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "SELECT * FROM eth_txes; DELETE from eth_txes WHERE state = 'unconfirmed'; {'  DELETE from job_runs WHERE status ='pending_outgoing_confirmations';'}"
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>SELECT * FROM eth_txes;</code>
              </li>
              <li></li>
              <li>
                <code>DELETE from eth_txes WHERE state = 'unconfirmed';</code>
              </li>
              <li></li>
              <li>
                <code>
                  DELETE from job_runs WHERE status =
                  'pending_outgoing_confirmations';
                </code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] cannot create transaction; too many unstarted
                transactions in the queue
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot create a new transaction as the queue
            limit is exceeded. <br /> <br />
            Many queued transactions indicate that a previous transaction is
            stuck, if the node is not able to rebroadcast it you may need to
            delete the unconfirmed transactions and pending job runs from the
            database manually.
          </div>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the node’s
              <code>ETH_MAX_QUEUED_TRANSACTIONS</code>
              setting
            </li>
            <li>
              Run these commands on your database to delete all unconfirmed
              transactions and pending job runs (always backup your node and
              database before you manually change anything in the database!)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
              <li>
                <code>4</code>
              </li>
              <li>
                <code>5</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "SELECT * FROM eth_txes; DELETE from eth_txes WHERE state = 'unconfirmed'; {'  DELETE from job_runs WHERE status ='pending_outgoing_confirmations';'} "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>SELECT * FROM eth_txes;</code>
              </li>
              <li></li>
              <li>
                <code>DELETE from eth_txes WHERE state = 'unconfirmed';</code>
              </li>
              <li></li>
              <li>
                <code>
                  DELETE from job_runs WHERE status =
                  'pending_outgoing_confirmations';
                </code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] EthBroadcaster: transaction throttling; transactions
                in-flight and unstarted transactions pending (maximum number of
                in-flight transactions is per key)
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The default value of maximum in-flight transactions is 16, so your
            Chainlink node may be struggling holding up with the frequency of
            incoming job requests. If the node successfully broadcasts
            transactions that do not get confirmed they could be underpriced and
            not being picked up by the network’s validators. If the node is not
            able to rebroadcast unconfirmed transactions you may need to delete
            these and the pending job runs from the database manually.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the node’s
              <code>GAS_ESTIMATOR_MODE</code> setting
            </li>
            <li>
              Check the node’s other{" "}
              <a
                className="inline-link"
                href="https://docs.chain.link/docs/configuration-variables/#gas-controls"
                target="_blank"
              >
                gas related settings
              </a>
            </li>
            <li>
              For high-throughput Chainlink nodes: configure your full node
              accordingly and increase
              <code>ETH_MAX_IN_FLIGHT_TRANSACTIONS</code> (Default: 16)
            </li>
            <li>
              Run these commands on your database to delete all unconfirmed
              transactions and pending job runs (always backup your node and
              database before you manually change anything in the database!)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
              <li>
                <code>4</code>
              </li>
              <li>
                <code>5</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "SELECT * FROM eth_txes; DELETE from eth_txes WHERE state = 'unconfirmed'; {'  DELETE from job_runs WHERE status ='pending_outgoing_confirmations';'} "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>SELECT * FROM eth_txes;</code>
              </li>
              <li></li>
              <li>
                <code>DELETE from eth_txes WHERE state = 'unconfirmed';</code>
              </li>
              <li></li>
              <li>
                <code>
                  DELETE from job_runs WHERE status =
                  'pending_outgoing_confirmations';
                </code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] EthBroadcaster: transaction gas price was rejected by
                the eth node for being too high.
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node tried to submit a transaction with a gas price
            exceeding the set limit of its remote RPC endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the Chainlink node’s blockchain connection (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>

          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node make sure to
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Consider to increase
              <code>RPCTxFeeCap</code>
            </li>
            <li>
              It is recommended to run Geth with no cap, i.e
              <code> --rpc.gascap=0 --rpc.txfeecap=0</code>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] EthBroadcaster: tx at gas price Wei was rejected due to
                insufficient eth
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The native token balance of the Chainlink node account is too low to
            submit the transaction.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Fund the Chainlink node account with a sufficient amount of the
              network’s native token
            </li>
            <li>
              You can view the node’s account address when the node starts up or
              on the Keys page of the web GUI
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-6">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] default gas price wei was rejected by the eth node for
                being too low
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node tried to submit a transaction with an
            insufficient gas price.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the <code>GAS_ESTIMATOR_MODE</code> setting
            </li>
            <li>
              Check the other{" "}
              <a
                className="inline-link"
                href="https://docs.chain.link/docs/configuration-variables/#gas-controls"
                target="_blank"
              >
                gas related settings
              </a>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-7">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Hit gas price bump ceiling, will not bump further. This
                is a terminal error
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node repeatedly bumped an unconfirmed transaction and
            will not continue to bump the gas price any further. This could be
            due to a network congestion or an internal bumping issue.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the
              <code>GAS_ESTIMATOR_MODE</code> setting
            </li>
            <li>
              Increase <code>ETH_MAX_GAS_PRICE_WEI</code> if necessary
            </li>
            <li>
              Restart the node to apply the changes to the environmental file
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-8">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] invariant violation: could not increment nonce because
                no rows matched query
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node cannot increment the nonce of the account, this
            may result from a modification of the nonce while using the account
            with another Chainlink instance or an external wallet.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure not to send transactions from an account that is used by
              a Chainlink node except through the Chainlink CLI itself,
              otherwise it could lead to unrecoverable nonce issues
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-9">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] EthConfirmer batchFetchReceipt failed
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The remote RPC endpoint used by the Chainlink node is not able to
            transfer the status of a broadcast transaction using the
            <code>eth_getTransactionReceipt</code>
            method. The Chainlink node needs the receipt to check if a
            transaction has already been mined or if further actions like
            resubmitting with a bumped gas price are necessary.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node make sure to
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Enable the <code>--txpool.locals</code> flag so that the full node
              can keep better track of the Chainlink node transactions that are
              sent to the mempool
            </li>
            <li>
              <code>--txpool.locals $CHAINLINK_NODE_ADDRESS</code>
            </li>
            <li>
              Increase
              <code>--txpool.globalslots</code>
              (Default: 2000)
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-10">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] Transaction reverted on chain
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Reverted on-chain transactions are often related to chain reorgs and
            the settings for minimum incoming confirmations. The Chainlink node
            may submit multiple transactions if it does not find the previous
            ones in the mempool so that they become “stale”, this may be due to
            a chain reorg or issues with the remote RPC endpoint (full node).
            Reverted transactions can also occur if several nodes from the same
            OCR network transmit their results for a certain round in a short
            time interval and only the transaction that is confirmed first is
            not reverted.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the <code>MIN_INCOMING_CONFIRMATIONS</code> setting (Default
              values have been tested and adjusted for each network, values
              smaller than 3 may result in reverted transactions)
            </li>
            <li>
              Only set <code>ETH_HTTP_URL</code> if you run your own full node
              to be sure to use the same full node on the backend (
              <code>ETH_URL</code> and
              <code>ETH_HTTP_URL</code>
              need to point to the same full node)
            </li>
          </ul>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If you are running an own full node make sure to
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Enable the <code>--txpool.locals </code>
              flag and add the addresses of your Chainlink node(s)
            </li>
            <li>
              <code>--txpool.locals $CHAINLINK_NODE_ADDRESS</code>
            </li>
            <li>
              Test different settings for
              <code>--peers</code> depending on the network (Default = 100)
            </li>
            <li>Less peers: probably less, but deeper reorgs</li>
            <li>More peers: probably more, but less deep reorgs</li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-11">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] EthConfirmer: eth_tx with ID expired without ever
                getting a receipt for any of our attempts
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Chainlink requires exclusive ownership of its private keys, sharing
            them across multiple Chainlink instances or using the keys with an
            external wallet is not supported and will lead to missed
            transactions.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure not to send transactions from an account that is used by
              a Chainlink node except through the Chainlink CLI itself,
              otherwise it could lead to unrecoverable nonce issues
            </li>
          </ul>
        </div>
        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-12">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] EthConfirmer: transactions to rebroadcast which exceeds
                limit of
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Too many transactions need to be rebroadcast and the limit of
            in-flight transactions is exceeded, so the Chainlink node is
            struggling to hold up with the frequency of incoming job requests.
            If the node successfully broadcasts transactions that do not get
            confirmed they may be underpriced and not be picked up by the
            network’s validators.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the
              <code>GAS_ESTIMATOR_MODE </code> setting
            </li>
            <li>
              Check the other{" "}
              <a
                className="inline-link"
                href="https://docs.chain.link/docs/configuration-variables/#gas-controls"
                target="_blank"
              >
                gas related settings
              </a>
            </li>
            <li>
              For high-throughput Chainlink nodes: configure the full node
              accordingly and increase
              <code>ETH_MAX_IN_FLIGHT_TRANSACTIONS</code> (Default: 16)
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-13">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Failed to bump gas
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The Chainlink node does not bump the gas for an unconfirmed
            transaction, instead it tries to resubmit the previous attempt until
            it gets accepted.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the
              <code>GAS_ESTIMATOR_MODE </code> setting
            </li>
            <li>
              Increase <code> ETH_MAX_GAS_PRICE_WEI </code> if necessary
            </li>
            <li>
              Restart the node to apply the changes to the environmental file
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-14">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] EthConfirmer: chain length supplied for re-org detection
                was shorter than EvmFinalityDepth. If this happens a lot, it
                could indicate a problem with the remote RPC endpoint, a
                compatibility issue with a particular blockchain, heads table
                being truncated too early, or some other problem
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>

          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
            <li>
              Make sure the network the node is connecting to is supported by
              Chainlink
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-15">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] EthResender: failed to resend unconfirmed transactions
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            This message may occur if gas bumping is disabled or the network is
            experiencing abnormally long block times. It may also be related to
            issues with the remote RPC endpoint (full node).
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check the
              <code>GAS_ESTIMATOR_MODE </code> setting
            </li>
            <li>
              Check the other{" "}
              <a
                className="inline-link"
                href="https://docs.chain.link/docs/configuration-variables/#gas-controls"
                target="_blank"
              >
                gas related settings
              </a>
            </li>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="bulletprooftxmanager_id-16">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] NonceSyncer: address has been used before, either by an
                external wallet or a different Chainlink node. Local nonce is
                but the on-chain nonce for this account was. It's possible that
                this node was restored from a backup. If so, transactions sent
                by the previous node will NOT be re-org protected and in rare
                cases may need to be manually bumped/resubmitted.
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            Chainlink requires exclusive ownership of its private keys, sharing
            them across multiple Chainlink instances or using the keys with an
            external wallet is not supported and will lead to missed
            transactions.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure not to send transactions from an account that is used by
              a Chainlink node except through the Chainlink CLI itself,
              otherwise it could lead to unrecoverable nonce issues
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BulletproofTxManager;
