import React, { useEffect } from "react";
import link from "../Assets/Icons/link.svg";
import linkDark from "../Assets/Icons/shareicon-dark.svg";
import { useLocation } from "react-router-dom";
import copyIcon from "../Assets/Icons/copy.png";

const Client = ({ theme }) => {
  const location = useLocation();

  useEffect(() => {
    let id = location.hash.split("#")[1];
    if (id) {
      const section = document.getElementById(id);
      const elementPosition = section.offsetTop;
      const offset = elementPosition - 130;
      if (section) {
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    assignEvents();
  }, []);

  const handleGetURL = async (id) => {
    if (window.location.href.includes("#")) {
      let base = window.location.href.split("#")[0];
      await navigator.clipboard.writeText(`${base}#${id}`);
    } else {
      await navigator.clipboard.writeText(`${window.location.href}#${id}`);
    }
  };
  const assignEvents = () => {
    const divElements = document.getElementsByClassName("log");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      let elementID = el.childNodes[0].id;
      const imgElement = el.childNodes[0].childNodes[0];
      imgElement.addEventListener("click", () => {
        let alert = el.childNodes[0].childNodes[2];
        alert.classList.add("copiedLink-active");
        setTimeout(() => {
          alert.classList.remove("copiedLink-active");
        }, 1000);
        handleGetURL(elementID);
      });
      imgElement.addEventListener("mouseover", () => {
        let alert = el.childNodes[0].childNodes[3];
        alert.classList.add("copyLink-active");
        setTimeout(() => {
          alert.classList.remove("copyLink-active");
        }, 500);
      });
    });
  };
  const showAlert = () => {
    const divElements = document.getElementsByClassName("codelines");
    const elArray = [...divElements];

    elArray.forEach((el) => {
      const imgElement = el.childNodes[0].childNodes[0];
      const alertCopy = el.childNodes[1];
      const alertCopied = el.childNodes[2];
      imgElement.addEventListener("click", () => {
        alertCopied.classList.add("copied-code-active");
        setTimeout(() => {
          alertCopied.classList.remove("copied-code-active");
        }, 1000);
      });
      imgElement.addEventListener("mouseover", () => {
        alertCopy.classList.add("copy-code-active");
        setTimeout(() => {
          alertCopy.classList.remove("copy-code-active");
        }, 1000);
      });
    });
  };
  return (
    <section className={theme === "dark" ? "section-dark" : "section"}>
      <div className={theme === "dark" ? "log-page-dark" : "log-page"}>
        <h1 className={theme === "dark" ? "log-header-dark" : "log-header"}>
          Client
        </h1>
        <p className={theme === "dark" ? "log-text-dark" : "log-text"}>
          The client interface enables interactions with a remote RPC endpoint
          (full node) and thus the target blockchain a Chainlink node should
          interact with, it captures the methods the internal Geth client can
          perform.
        </p>
        <div className="log-divider"></div>

        <div className="log">
          <h2 className="log-title" id="client_id-1">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] ethereum url scheme must be websocket
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The websocket address of the remote RPC endpoint used by the
            Chainlink node must be set correctly in order to establish a working
            connection with the target network.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure the ETH_URL scheme starts with <code>ws://</code> or
              <code>wss://</code>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="client_id-2">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] secondary ethereum rpc url scheme must be http(s)
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            The secondary RPC endpoint(s) must be specified correctly in order
            to relieve the primary full node when sending transactions.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure the scheme looks like this:
              <code>
                ETH_SECONDARY_URLS=http(s)://node.com/1,https://logs.com/2,...
              </code>
            </li>
          </ul>
        </div>

        <div className="log">
          <h2 className="log-title" id="client_id-3">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Failed to dial primary client
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            <code>Dial() </code>
            creates a new internal client that connects to the remote RPC
            endpoint (full node) set with the <code>ETH_URL</code> environmental
            variable. It initiates the blockchain connection of the Chainlink
            node and automatically tries to reconnect if it is interrupted.
            <br /> If this error message occurs the Chainlink node cannot be
            (re-)initialized as it does not have a working blockchain
            connection.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check <code>ETH_URL </code> for correctness
            </li>
            <li>
              Check the functionality of the connection manually (e.g. by using
              a program like wscat to establish a <code>newHeads</code>{" "}
              subscription)
            </li>
          </ul>

          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
            </ul>
            <ul className="codelines">
              <button
                className="copy-button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "wscat -c ws://$RPC_URL 'id': 2, 'method': 'eth_subscribe'. 'params': [newHeads] "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
              <li>
                <code>wscat -c ws://$RPC_URL</code>
              </li>
              <li></li>
              <li>
                <code>
                  "id": 2, "method": "eth_subscribe", "params": [newHeads"]
                </code>
              </li>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="client_id-4">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [ERROR] Failed to dial secondary client
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            <code>Dial() </code>
            creates a new internal client that connects to the remote RPC
            endpoint(s) (full node) set with the{" "}
            <code> ETH_SECONDARY_URLSenvironmental</code> variable.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Check <code>ETH_SECONDARY_URLS </code> for correctness
            </li>
            <li>
              Check the connection’s functionality manually (e.g. by sending a
              JSON-RPC request to the RPC endpoint)
            </li>
          </ul>
          <div class="code-snippet">
            <ul className="codelinesnum">
              <li>
                <code>1</code>
              </li>
            </ul>
            <ul className="codelines-singleline">
              <li>
                <code>
                  curl -H "Content-Type: application/json" -d
                  '"jsonrpc":"2.0","method":"eth_getBlockByNumber","params":["latest",true],
                  "id":1' http://$RPC_URL
                </code>
              </li>
              <button
                className="copy-button-singleline"
                onClick={() =>
                  navigator.clipboard.writeText(
                    "wscat -c ws://$RPC_URL 'id': 2, 'method': 'eth_subscribe'. 'params': [newHeads] "
                  )
                }
                onMouseEnter={showAlert}
              >
                <img src={copyIcon} onClick={showAlert} className="copyicon" />
              </button>
              <div className="copy-code">Copy</div>
              <div className="copied-code">Copied!</div>
            </ul>
          </div>
        </div>

        <div className="log">
          <h2 className="log-title" id="client_id-5">
            <img
              src={theme === "dark" ? linkDark : link}
              className="share-icon"
            />
            <div
              className={
                theme === "dark"
                  ? "log-title-wrapper-dark"
                  : "log-title-wrapper"
              }
            >
              <div className={theme === "dark" ? "log-name-dark" : "log-name"}>
                [WARN] secondary eth client returned error
              </div>
            </div>
            <div className="copiedLink">Link copied!</div>
            <div className="copyLink">Copy</div>
          </h2>
          <div
            className={theme === "dark" ? "log-content-dark" : "log-content"}
          >
            If <code>ETH_SECONDARY_URLS </code>is set the Chainlink node
            broadcasts every transaction to the remote RPC endpoints in
            parallel.
          </div>
          <ul className={theme === "dark" ? "log-notes-dark" : "log-notes"}>
            <li>
              Make sure to use different full nodes as primary and secondary
              ones
            </li>
            <li>
              Check the blockchain connection of the Chainlink node (e.g. the
              Full-node-as-a-Service subscription and renew or switch the plan
              if necessary to prevent RPC rate limits from being hit)
            </li>
            <li>
              Run an own full node with custom configuration and no performance
              restrictions
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Client;
