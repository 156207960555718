import React, { useState, useEffect } from "react";
import logsData from "../../Assets/data/logs.json";

import logo from "../../Assets/Icons/logo.svg";
import moon from "../../Assets/Icons/moon.svg";
import sun from "../../Assets/Icons/sun.svg";
import twitter from "../../Assets/Icons/twitter.svg";
import discord from "../../Assets/Icons/discord.svg";
import vLine from "../../Assets/Icons/v-line.svg";
import searchIcon from "../../Assets/images/search.png";
import searchDark from "../../Assets/Icons/searchDark.svg";

import { useHistory, Link, useLocation } from "react-router-dom";

import "./header.css";

let errorLogs = [];

for (const log of logsData) {
  for (const key in log) {
    errorLogs.push(log[key]);
  }
}

let merged = [].concat.apply([], errorLogs);

const Header = ({ toggleTheme, theme }) => {
  let history = useHistory();
  let location = useLocation();
  const [logs, setLogs] = useState(null);
  const [searchBar, setSearchBar] = useState(false);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);

    if (location.pathname === "/") {
      setSearchBar(false);
    } else {
      setSearchBar(true);
    }
  }, [location.pathname]);

  const handleChange = (e) => {
    const log = merged.filter((log) =>
      log.value.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (log.length > 0) {
      setLogs(log[0]);
    } else {
      setLogs(null);
    }
  };
  const handleSearch = (e) => {
    let userInput = e.target.value;
    let similarityLength = 0;
    let chosenLog = null;

    merged.forEach((log) => {
      let result = similarity(userInput, log.value);
      if (result > similarityLength) {
        similarityLength = result;
        chosenLog = log;
      }
    });

    e.charCode === 13 && history.push(`${chosenLog?.linkTo}#${chosenLog?._id}`);
  };

  function similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    let longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    let costs = new Array();
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }
  return (
    <header className="header-container">
      <div className={theme == "dark" ? "header-dark" : "header"}>
        <div className="header-wrapper">
          <Link to="/" className="logo-wrapper">
            <img src={logo} className="header-logo" />
          </Link>
          <div className="header-actions">
            <img
              src={theme === "dark" ? sun : moon}
              className="header-icon"
              onClick={toggleTheme}
            />
            <img src={vLine} className="header-icon" />
            <a href="https://twitter.com/nodelogs" target="_blank">
              <img src={twitter} className="header-icon" />
            </a>
            <a href="https://discord.com/invite/xRWKtpjA9F" target="_blank">
              <img src={discord} className="header-icon" />
            </a>
          </div>
        </div>
      </div>
      {searchBar && (
        <div
          className={
            theme === "dark"
              ? "detailspage-search-container-dark"
              : "detailspage-search-container"
          }
        >
          <div
            className={
              theme === "dark"
                ? "detailspage-search-dark"
                : "detailspage-search"
            }
          >
            <img
              src={theme === "dark" ? searchDark : searchIcon}
              className="landing-searchIcon"
            />
            <input
              placeholder="Search for log messages..."
              className={
                theme === "dark"
                  ? "detailspage-input-dark"
                  : "detailspage-input"
              }
              name="errorLogs"
              onKeyPress={handleSearch}
              onChange={handleChange}
              id="searchInput"
            />
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
