import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import sidebarToggler from "../../Assets/images/toggleDocsIcon.png";
import sidemenuIcon from "../../Assets/images/sidemenuIcon.png";
import close from "../../Assets/Icons/close.svg";

import "./sidebar.css";

const Sidebar = ({ theme }) => {
  const [sidebar, setSidebar] = useState(false);

  const setSidebarActive = () => {
    setSidebar(!sidebar);
  };
  return (
    <>
      {sidebar ? (
        <>
          <div className={theme === "dark" ? "sidebar-dark" : "sidebar"}>
            <div onClick={setSidebarActive}>
              <img
                src={close}
                className={
                  theme === "dark" ? "sidebar-close-dark" : "sidebar-close"
                }
              />
            </div>
            <div className="sidebar-content">
              <div
                className={
                  theme === "dark" ? "sidebar-topic-dark" : "sidebar-topic"
                }
              >
                Chainlink Node Services
              </div>
              <div>
                <NavLink
                  to="/balancemonitor"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  BalanceMonitor
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/bulletprooftxmanager"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  BulletproofTxManager
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/client"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  Client
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/directrequest"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  DirectRequest
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/fluxmonitor"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  FluxMonitor
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/gasestimator"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  GasEstimator
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/headtracker"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  HeadTracker
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/keystore"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  Keystore
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/log"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  Log
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/ocr"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  OCR
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/periodicbackups"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  PeriodicBackups
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/postgres"
                  className={
                    theme === "dark"
                      ? "sidebar-options-dark"
                      : "sidebar-options"
                  }
                  onClick={() => setSidebar(!sidebar)}
                >
                  Postgres
                </NavLink>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div onClick={setSidebarActive} className="toggler-icon">
          <img src={theme === "dark" ? sidemenuIcon : sidebarToggler} />
        </div>
      )}
    </>
  );
};

export default Sidebar;
